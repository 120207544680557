.main-container {
  width: 80%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 12rem;
}

.info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.illustration {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.construction-img {
  width: 70%;
}

.company-name {
  font-size: 4rem;
  font-weight: bold;
}

@media only screen and (max-width: 700px) {
  .main-container {
    flex-direction: column-reverse;
    padding-top: 2rem;
  }
  .info {
    width: 100%;
  }
  .illustration {
    width: 100%;
  }
  .company-name {
    font-size: 2rem;
  }
}
